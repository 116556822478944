
.square {
  background: #fff;
  border: 1px solid #999;
  float: left;
  font-size: 50px;
  font-weight: bold;
  margin-right: -1px;
  margin-top: -1px;
  padding: 0;
  text-align: center;
  width: 80px;
  line-height: 80px;
  height: 80px;
  display: inline-block;
    text-decoration: none;
    color: #fff;
    font-weight: bold;
    background-color: #be5712;
    margin: 10px;
    border: 1px solid #8f472b;
    background-image: linear-gradient(bottom, rgb(187, 92, 36) 0%, rgb(187, 92, 36) 100%);
    background-image: -o-linear-gradient(bottom, rgb(187, 92, 36) 0%, rgb(187, 92, 36) 100%);
    background-image: -moz-linear-gradient(bottom, rgb(187, 92, 36) 0%, rgb(187, 92, 36) 100%);
    background-image: -webkit-linear-gradient(bottom, rgb(187, 92, 36) 0%, rgb(187, 92, 36) 100%);
    background-image: -ms-linear-gradient(bottom, rgb(187, 92, 36) 0%, rgb(187, 92, 36) 100%);
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    border-radius: 5px;
    text-shadow: 0px -1px 0px rgba(0,0,0,.5);
    -webkit-box-shadow: 0px 6px 0px #8f472b, 0px 3px 15px rgba(0,0,0,.4), inset 0px 1px 0px rgba(255,255,255,.3), inset 0px 0px 3px rgba(255,255,255,.5);
    -moz-box-shadow: 0px 6px 0px #8f472b, 0px 3px 15px rgba(0,0,0,.4), inset 0px 1px 0px rgba(255,255,255,.3), inset 0px 0px 3px rgba(255,255,255,.5);
    box-shadow: 0px 6px 0px #8f472b, 0px 3px 15px rgba(0,0,0,.4), inset 0px 1px 0px rgba(255,255,255,.3), inset 0px 0px 3px rgba(255,255,255,.5);
 
 }
 /* Board */
.board-row:after {
  clear: both;
  content: "";
  display: table;
 }
 /* Game */
.game {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  flex-direction: row;
  margin: 0 auto;  
 }
 /* Game Info */
ul {
  padding-left: 30px;
 }
 .game-info {
  margin-left: 20px;
 }
 .status {
  margin-bottom: 10px;
 }
 